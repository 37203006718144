<template>
  <div class="appointments">
    <DataTable
      class="p-datatable-sm"
      :rowClass="rowClass"
      :rowHover="true"
      :value="appointments"
      :loading="isLoading"
      editMode="cell"
      :lazy="true"
      filterDisplay="row"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      :totalRecords="getAppointmentCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      responsiveLayout="stack"
      breakpoint="1200px"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <div class="mt-2">
              <span v-if="calendarWeek[0] && calendarWeek[1]">
                {{ getAppointments ? getAppointments.length : 0 }} Termine im Zeitraum
                {{ calendarWeek[0] ? $date(calendarWeek[0]) : 'N/A' }} bis
                {{ calendarWeek[1] ? $date(calendarWeek[1]) : 'N/A' }}
              </span>
              <span v-else>
                {{ $t('today') }}
                {{ getAppointmentCount }}
                {{ $tc('appointment_pluralized', getAppointmentCount) }}
              </span>
            </div>
          </div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>
      <Column
        field="installationStartAt"
        :header="$t('start')"
        :styles="{ width: '90px' }"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          <div>
            <strong>{{ $dayjs(slotProps.data[slotProps.column.field]).format('ddd') }}</strong>
            {{ $dayjs(slotProps.data[slotProps.column.field]).format('DD.MM HH:mm') }}
          </div>
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="
              () => {
                filterCallback();
                onFilter({ filters: { ...tableState.filters, installationStartAt: filterModel } });
              }
            "
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="installationEndAt"
        :header="$t('end')"
        :styles="{ width: '90px' }"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          {{ $dayjs(slotProps.data[slotProps.column.field]).format('DD.MM HH:mm') }}
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>

        <template #filter="{filterModel, filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="
              () => {
                filterCallback();
                onFilter({ filters: { ...tableState.filters, installationEndAt: filterModel } });
              }
            "
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder="<="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="appointmentType"
        :header="$t('appointmentType')"
        :styles="{ width: '7%' }"
        :showFilterMenu="false"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentTypeColor(slotProps.data.appointmentType)">
            {{ $t(slotProps.data.appointmentType) }}
          </b-badge>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentTypes"
            :options="appointmentTypes"
            @input="onAppointmentTypeFilter"
            optionLabel="label"
            :placeholder="$t('appointmentType')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column
        field="appointmentState"
        :header="$t('appointmentState')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getAppointmentStateColor(slotProps.data.appointmentState)">
            {{ $t(slotProps.data.appointmentState) }}
          </b-badge>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.appointmentStates"
            :options="appointmentStates"
            @input="onAppointmentStateFilter"
            optionLabel="label"
            :placeholder="$t('appointmentState')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column
        field="project.number"
        header="#"
        :headerStyle="{ width: '60px' }"
        headerClass="center"
        className="center"
        filterField="projectNumber"
        sortable
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.project && slotProps.data.project.number"
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.project.number },
            }"
            >{{ slotProps.data.project.number.toString().padStart(4, '0') }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="project.employerProjectNumber"
        :header="$t('employerProjectNumber_short')"
        :headerStyle="{ width: '100px' }"
        headerClass="center"
        className="center"
        filterField="employerProjectNumber"
        sortable
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.project">
            {{ slotProps.data.project.employerProjectNumber }}
          </div>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="project"
        :header="$t('customer')"
        sortable
        :headerStyle="{ 'min-width': '25%' }"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.project && slotProps.data.project.customer">
            <div>
              {{ slotProps.data.project.customer.lastname }},
              {{ slotProps.data.project.customer.firstname }},
            </div>
            <div>
              {{ slotProps.data.project.customer.street }}
              {{ slotProps.data.project.customer.streetNumber }},
              {{ slotProps.data.project.customer.streetNumberSuffix }},
              {{ slotProps.data.project.customer.zip }}
              {{ slotProps.data.project.customer.city }}
            </div>
          </div>
        </template>

        <template #filter>
          <InputText
            :value="tableState.customFilters.projectCustomerSearch.value"
            @input="onProjectFilter"
          />
        </template>
      </Column>

      <Column :header="$t('verificationState')">
        <template #body="slotProps">
          <div>
            <div
              v-if="slotProps.data.project && slotProps.data.project.substructureVerificationState"
            >
              <b-badge
                class="verificationState"
                :variant="
                  verificationStateColor(slotProps.data.project.substructureVerificationState)
                "
              >
                {{ $t('substructureVerificationState') }}:
                {{ $t(slotProps.data.project.substructureVerificationState) }}
              </b-badge>
            </div>
            <div v-if="slotProps.data.project && slotProps.data.project.dcVerificationState">
              <b-badge
                class="verificationState"
                :variant="verificationStateColor(slotProps.data.project.dcVerificationState)"
              >
                {{ $t('dcVerificationState') }}:
                {{ $t(slotProps.data.project.dcVerificationState) }}
              </b-badge>
            </div>
            <div v-if="slotProps.data.project && slotProps.data.project.acVerificationState">
              <b-badge
                class="verificationState"
                :variant="verificationStateColor(slotProps.data.project.acVerificationState)"
              >
                {{ $t('acVerificationState') }}:
                {{ $t(slotProps.data.project.acVerificationState) }}
              </b-badge>
            </div>
            <div
              v-if="slotProps.data.project && slotProps.data.project.measurementVerificationState"
            >
              <b-badge
                class="verificationState"
                :variant="
                  verificationStateColor(slotProps.data.project.measurementVerificationState)
                "
              >
                {{ $t('measurementVerificationState') }}:
                {{ $t(slotProps.data.project.measurementVerificationState) }}
              </b-badge>
            </div>
            <div v-if="slotProps.data.project && slotProps.data.project.serviceVerificationState">
              <b-badge
                class="verificationState"
                :variant="verificationStateColor(slotProps.data.project.serviceVerificationState)"
              >
                {{ $t('serviceVerificationState') }}:
                {{ $t(slotProps.data.project.serviceVerificationState) }}
              </b-badge>
            </div>
          </div>
        </template>
      </Column>

      <Column field="employees" :header="$t('employee')" sortable>
        <template #body="slotProps">
          <ul class="compact">
            <li v-for="(employee, index) in slotProps.data.employees" :key="index">
              {{ employee.firstname }} {{ employee.lastname }}
            </li>
          </ul>
        </template>

        <template #filter>
          <InputText
            :value="tableState.customFilters.employeeSearch.value"
            @input="onEmployeeSearch"
          />
        </template>
      </Column>

      <Column field="itemStorages" :header="$t('vehicles')" sortable>
        <template #body="slotProps">
          <ul class="compact">
            <li v-for="(vehicle, index) in slotProps.data.itemStorages" :key="index">
              {{ vehicle.name }}
            </li>
          </ul>
        </template>

        <template #filter>
          <InputText
            :value="tableState.customFilters.itemStorageSearch.value"
            @input="onItemStorageSearch"
          />
        </template>
      </Column>

      <Column field="description" :header="$t('notes')" sortable :styles="{ 'min-width': '200px' }">
        <template #body="slotProps">{{ slotProps.data[slotProps.column.field] }} </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="project.employer.name"
        :header="$t('employer')"
        headerClass="center"
        className="center"
        filterField="projectEmployerSearch"
        sortable
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';
import { yesNo } from '@/helpers/enums';
import { FilterMatchMode } from 'primevue/api';
import { verificationStateColor } from '@/helpers/colors';
import Calendar from 'primevue/calendar';

export default {
  name: 'Appointments',
  components: {
    DataTable,
    Calendar,
    Column,
    InputText,
    MultiSelect,
  },
  data() {
    return {
      pollState: null,
      intervalInMilliseconds: 15000,
      displayBacklogConfirmation: false,
      tmpBacklogAppointment: null,
      oldAppointmentState: null,
      monthChangeClicked: false,
      selectingEndDate: false,
      copyDate: this.$dayjs()
        .startOf('day')
        .add(8, 'hour')
        .toDate(),
      calendarWeek: [],
      filteredEmployees: [],
      filteredItemStorages: [],
      loading: false,

      /** filters for project multiselect */
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 20,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'appointments-today-project-filters',
        filters: {
          number: { value: null, matchMode: FilterMatchMode.EQUALS },
          customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
          customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },

      defaultFilters: {
        projectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        employerProjectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        projectCustomerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectEmployerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectSolarPanelCount: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        installationStartAt: {
          value: this.$dayjs()
            .startOf('day')
            .toDate(),
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        installationEndAt: {
          value: this.$dayjs()
            .add(1, 'day')
            .startOf('day')
            .toDate(),
          matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        },
      },

      defaultCustomFilters: {
        appointmentStates: [],
        appointmentTypes: [],
        appointmentApprovedState: { value: null, matchMode: FilterMatchMode.EQUALS },
        employeeSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        itemStorageSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectCustomerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      tableState: {
        filterName: 'appointments-today-table-filters',
        customFilterName: 'appointments-today-custom-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'installationStartAt',
        sortOrder: -1,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getAppointments',
      'getEnumValues',
      'getProjects',
      'getEmployees',
      'getAppointmentCount',
      'getItemStorages',
      'getAppointmentByNumber',
    ]),
    appointmentStates() {
      const appointmentStates = this.getEnumValues('AppointmentState').map((appointmentState) => {
        return { value: appointmentState, label: this.$t(appointmentState) };
      });
      return appointmentStates;
    },
    appointmentTypes() {
      const appointmentTypes = this.getEnumValues('AppointmentType').map((appointmentType) => {
        return { value: appointmentType, label: this.$t(appointmentType) };
      });
      return appointmentTypes;
    },
    appointments() {
      return this.getAppointments;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchAppointmentPages',
      'fetchAppointmentByNumber',
      'createAppointment',
      'duplicateAppointment',
      'updateAppointment',
      'initAppointment',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployees',
      'deleteAppointment',
      'sendAppointmentMail',
      'fetchItemStoragesPaginated',
      'fetchProjectEditingState',
    ]),

    /**
     * search in dropdown for a project to assign to the appointment
     */
    async onProjectSearch(rawQuery) {
      const query = rawQuery.toLowerCase();
      this.projectsFilter.filters.customerLastname.value = query;
      await this.loadProjectData();
    },

    /**
     * only enable email button if customer has the email field filled
     */
    emailButtonDisabled(appointment) {
      return !(appointment.project && appointment.project.customer.email) ? true : false;
    },

    /**
     *
     */
    async onFilterEmployees(query) {
      // console.log(query);
      this.filteredEmployees = [];
      this.getEmployees.map((employee) => {
        if (
          (employee.firstname && employee.firstname.toLowerCase().indexOf(query) >= 0) ||
          (employee.lastname && employee.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          this.filteredEmployees.push(employee);
        }
      });
    },

    async onSelectProject(row) {
      // console.log('onSelectProject', row);
      const { data } = row;
      this.save(data);
    },

    async onSelectEmployee(row) {
      // console.log('onSelectEmployee', row);
      const { data } = row;
      await this.save(data);
    },

    async onFilterItemStorages(query) {
      this.filteredItemStorages = [];
      this.getItemStorages.map((itemStorage) => {
        if (itemStorage.name && itemStorage.name.toLowerCase().indexOf(query) >= 0) {
          // console.log(itemStorage);
          this.filteredItemStorages.push(itemStorage);
        }
      });
    },

    async onSelectItemStorage(row) {
      // console.log('onSelectItemStorage', row);
      const { data } = row;
      this.save(data);
    },

    onAppointmentStateChange(event) {
      // console.log('onAppointmentStateChange():', event);
      event.originalEvent.preventDefault();
      this.displayBacklogConfirmation = true;
    },

    onCancelBacklogReason() {
      this.tmpBacklogAppointment.appointmentState = this.oldAppointmentState;
      this.displayBacklogConfirmation = false;
    },
    onSaveBacklogReason() {
      this.save(this.tmpBacklogAppointment);
      this.displayBacklogConfirmation = false;
    },

    /**
     * send appointment data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, value, field } = event;
      console.log('onCellEditComplete():', event);

      /** Popup on backlog and alternative date */
      if (
        (field === 'appointmentState' && newValue === 'BACKLOG') ||
        newValue === 'ALTERNATIVE_DATE'
      ) {
        // console.log(data, newValue, field);

        /** Init Backlog */
        if (!data.backlog) {
          data.backlog = {
            backlogReason: 'WEATHER',
            description: '',
            remainingWork: '',
            remainingTimeExpenditure: 'DAY',
          };
        }

        this.oldAppointmentState = value;
        data[field] = newValue;
        this.tmpBacklogAppointment = data;
        this.displayBacklogConfirmation = true;
        return;
      }

      if (field === 'installationStartAt') {
        // update date grouping (day of week)
        data.weekday = this.$dayjs(newValue)
          .startOf('day')
          .format('dddd DD.MM.YYYY');

        // set installationEndAt to installationStartAt + 4 hour
        data.installationEndAt = this.$dayjs(newValue)
          .add(8, 'hour')
          .toDate();
      }

      data[field] = newValue;
      this.save(data);
    },

    /**
     * Save appointment
     * @param {*} appointment
     */
    async save(appointment) {
      if (appointment.number === '_new') {
        await this.createAppointment(appointment);
      } else {
        await this.updateAppointment(appointment);
      }
    },

    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      // return data.isOutdated ? 'outdated' : '';
      return data.markedAsChanged ? 'outdated' : '';
    },

    async loadProjectData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      }
      this.filteredProjects = this.getProjects;
    },

    /**
     * Load remote table data
     */
    async loadAppointmentData() {
      await this.fetchAppointmentPages({
        page: this.pageOffset,
        pageSize: this.tableState.pagination.rowsPerPage,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: {
          ...this.tableState.filters,
          ...this.tableState.customFilters,
          ...this.tableState.staticFilters,
        },
      });

      // await this.appointmentsAreOutdated();
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      if (event.filters.installationStartAt && event.filters.installationStartAt.value) {
        const startDate = event.filters.installationStartAt.value;
        const endDate = event.filters.installationEndAt
          ? event.filters.installationEndAt.value
          : null;

        if (!endDate || this.$dayjs(endDate).isAfter(this.$dayjs(startDate).add(4, 'weeks'))) {
          event.filters.installationEndAt = {
            value: this.$dayjs(startDate)
              .add(4, 'weeks')
              .toDate(),
            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
          };
        }
      }

      if (event.filters.installationEndAt && event.filters.installationEndAt.value) {
        const endDate = event.filters.installationEndAt.value;
        const startDate = event.filters.installationStartAt
          ? event.filters.installationStartAt.value
          : null;

        if (
          !startDate ||
          this.$dayjs(startDate).isBefore(this.$dayjs(endDate).subtract(4, 'weeks'))
        ) {
          event.filters.installationStartAt = {
            value: this.$dayjs(endDate)
              .subtract(4, 'weeks')
              .toDate(),
            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
          };
        }
      }

      this.calendarWeek = [
        event.filters.installationStartAt ? event.filters.installationStartAt.value : null,
        event.filters.installationEndAt ? event.filters.installationEndAt.value : null,
      ];

      this.tableState.filters = event.filters;
      await this.loadAppointmentData();
    },
    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      // console.log('onPage', event);
      this.loadAppointmentData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentApprovedStateFilter(value) {
      if (this.tableState.customFilters.appointmentApprovedState) {
        this.tableState.customFilters.appointmentApprovedState.value = value;
      }

      await this.loadAppointmentData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      // console.log('onStateRestore():', this.tableState);

      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem(this.tableState.customFilterName)
      );
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;

      this.setupFilters();
    },

    /**
     *
     */
    async onAppointmentStateFilter(value) {
      this.tableState.customFilters.appointmentStates = this.appointmentStates.filter(
        (appointmentState) => value.includes(appointmentState)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );
      await this.loadAppointmentData();
    },

    /**
     *
     */
    async onAppointmentTypeFilter(value) {
      this.tableState.customFilters.appointmentTypes = this.appointmentTypes.filter(
        (appointmentType) => value.includes(appointmentType)
      );
      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    /**
     * Search for assigned employees and save state to local storage
     */
    async onEmployeeSearch(value) {
      if (this.tableState.customFilters.employeeSearch) {
        this.tableState.customFilters.employeeSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    /**
     * Search for assigned itemStorages and save state to local storage
     */
    async onItemStorageSearch(value) {
      if (this.tableState.customFilters.itemStorageSearch) {
        this.tableState.customFilters.itemStorageSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    /**
     * Search and filter for fields in projects that are assigned to the appointment
     */
    async onProjectFilter(value) {
      if (this.tableState.customFilters.projectCustomerSearch) {
        this.tableState.customFilters.projectCustomerSearch.value = value;
      }

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadAppointmentData();
    },

    async onRemoveFilters(event) {
      this.tableState.filters = {
        projectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        employerProjectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        projectCustomerZip: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectEmployerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        projectSolarPanelCount: {
          value: null,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        installationStartAt: {
          value: this.$dayjs()
            .startOf('day')
            .toDate(),
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        installationEndAt: {
          value: this.$dayjs()
            .add(1, 'day')
            .startOf('day')
            .toDate(),
          matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        },
      };

      this.tableState.customFilters = this.defaultCustomFilters;

      this.calendarWeek = [null, null];

      localStorage.removeItem(this.tableState.filterName);
      localStorage.removeItem(this.tableState.customFilterName);
      await this.loadAppointmentData();
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;

      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    async appointmentsAreOutdated() {
      for (let appointment of this.appointments) {
        this.updateAndMarkAppointment(appointment);
      }
    },

    async updateAndMarkAppointment(appointment) {
      if (!appointment.project) return;

      // fetch new data and mark as changed
      if (appointment.isOutdated) {
        // console.log('updateAndMarkAppointment() updating ', appointment.project.number, appointment.isOutdated);

        await this.fetchAppointmentByNumber(appointment.number);

        // appointment.isOutdated = false;
        appointment.markedAsChanged = true;

        // console.log(
        //   'updateAndMarkAppointment() after update ',
        //   appointment.project.number,
        //   appointment.isOutdated,
        //   appointment.markedAsChanged
        // );
      }

      const outdated = await this.fetchProjectEditingState(appointment.project.number);
      appointment.isOutdated = outdated.projectIsOutdated;
      appointment.markedAsChanged = outdated.projectIsOutdated;
      // console.log('updateAndMarkAppointment()', appointment.project.number, outdated);
    },

    async pollIsOutdated() {
      this.pollState = setInterval(async () => {
        // await this.updateOutdatedAppointments();
        await this.appointmentsAreOutdated();
      }, this.intervalInMilliseconds);
    },

    getAppointmentTypeColor,
    getAppointmentStateColor,
    getYesNoStateColor,
    yesNo,
    verificationStateColor,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    await this.fetchEnumValues('AppointmentType');
    await this.fetchEnumValues('AppointmentState');
    await this.fetchEnumValues('BacklogReason');
    await this.fetchEnumValues('RemainingTimeExpenditure');
    await this.fetchEmployees({
      page: 0,
      pageSize: 1000,
      sortField: 'lastname',
      sortOrder: 1,
      filters: { activeState: { value: 'ACTIVE' } },
    });
    await this.fetchItemStoragesPaginated({
      pagination: { skip: 0, pageSize: 100 },
      sorting: { sortField: 'number', sortOrder: 1 },
      filters: { itemStorageType: { value: 'VEHICLE', matchMode: FilterMatchMode.CONTAINS } },
    });
    this.filteredEmployees = this.getEmployees;
    this.filteredItemStorages = this.getItemStorages;
    await this.loadAppointmentData();
    // await this.pollIsOutdated();
    await this.loadProjectData();
  },
};
</script>

<style scoped lang="scss">
ul.compact {
  list-style-type: none;
  padding: 0;
}

.badge.verificationState {
  min-width: 170px;
}

::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.outdated {
  background: $light-orange;
}
::v-deep .p-datatable .p-datatable-tbody > tr.outdated:hover {
  background: $medium-orange;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-employees .multiselect__input {
  padding-top: 1em;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
.expansion {
  text-align: left;
  margin-left: 1.5em;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1em;
}
.grid > div {
  padding: 1em 1em;
  border: 2px;
  border: 1px solid $gray-300;
  background: white;
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 75vh;
}
</style>
