<template>
  <Card>
    <template #title>
      Meine Aufgaben
    </template>
    <template #content>
      <div v-if="getUserTasks.length === 0">Derzeit keine Aufgaben</div>
      <ScrollPanel style="height:500px">
        <div v-for="(task, index) in getUserTasks" :key="index" class="task-item">
          <div class="card-grid-container">
            <div>
              <strong> {{ task.number }}</strong>
            </div>
            <div>
              <router-link
                v-if="task.project && task.project.number"
                target="_blank"
                :to="{
                  name: 'ProjectEditPage',
                  params: { projectNumber: task.project.number },
                }"
                >{{ task.project.customer.lastname }}, {{ task.project.customer.firstname }}
                <i class="fa fa-sm fa-external-link"></i>
              </router-link>
            </div>
            <div>{{ $datetime(task.createdAt) }}</div>
            <div>
              <b-badge :variant="getTaskStateColor(task.taskState)">
                {{ $t(task.taskState) }}
              </b-badge>
            </div>
          </div>
          <div>
            <div>{{ task.description }}</div>
          </div>
        </div>
      </ScrollPanel>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';
import { mapGetters, mapActions } from 'vuex';
import { getTaskStateColor } from '@/helpers/colors';
import ScrollPanel from 'primevue/scrollpanel';

export default {
  components: {
    Card,
    ScrollPanel,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getUserTasks', 'getUserTaskCount']),
    userNumberAsString() {
      return this.getCurrentUser.number.toString();
    },
  },
  methods: {
    ...mapActions(['fetchTasks']),

    /**
     * Load remote table data
     */
    async loadTaskData() {
      await this.fetchTasks({
        page: 0,
        pageSize: 100,
        sortField: 'number',
        sortOrder: -1,
        filters: {
          inChargeNumber: { value: this.userNumberAsString },
          taskStates: [{ value: 'NEW' }, { value: 'UNSOLVABLE' }, { value: 'IN_PROGRESS' }],
        },
        taskListType: 'USER_TASKS',
      });

      this.filteredProjects = this.getProjects;
    },
    getTaskStateColor,
  },

  async mounted() {
    await this.loadTaskData();
  },
};
</script>

<style scoped lang="scss">
.task-item {
  background: rgba(0, 0, 32, 0.05);
  margin: 0.5em 0;
  padding: 0.5em;
}

.card-grid-container {
  display: grid;
  grid-template-columns: 35px 1fr 1fr 80px;
  row-gap: 0.5em;
  column-gap: 0.5em;
  margin-bottom: 0.5em;
  justify-items: start;
}
.task-item:hover {
  background: rgba(0, 0, 32, 0.1);
}

::v-deep .p-scrollpanel-bar {
  background-color: $gray-400;
  opacity: 0.5;
  transition: background-color 0.2s;

  &:hover {
    background-color: $gray-400;
  }
}
</style>
