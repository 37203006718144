<template v-if="projects">
  <Card>
    <template #title>Projekte mit Bestellliste: {{ projectCount }}</template>
    <template #content>
      <div v-for="project in projects" :key="project.number" class="project-entry">
        <div class="project-details">
          <span class="project-number">{{ project.number }}</span>
          <router-link
            v-if="project.customer"
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: project.number },
              query: { projectTab: 'orders' },
            }"
            class="customer-link"
          >
            {{ project.customer.lastname }}, {{ project.customer.firstname }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
          <span v-if="project.customer" class="project-location">
            PLZ: {{ project.customer.zip }}
          </span>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import {
  getYesNoStateColor,
  getProjectStateColor,
  yesNoStateReversedColor,
} from '@/helpers/colors';
import { yesNo, projectState } from '@/helpers/enums';
import Card from 'primevue/card';

export default {
  components: {
    Card,
  },
  data() {
    return {
      loading: false,
      selectedColumns: [],
      columns: [
        { field: 'employerName', header: this.$t('EMPLOYER_SHORT') },
        { field: 'clientName', header: this.$t('client') },
        { field: 'projectState', header: this.$t('projectState') },
      ],
      defaultSortField: 'number',
      defaultSortOrder: -1,
      defaultFilters: {
        onlyWithItems: {
          value: 'true',
          matchMode: FilterMatchMode.EQUALS,
        },
      },
      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        sortField: this.defaultSortField,
        sortOrder: this.defaultSortOrder,
        filterName: 'projects-paginated-items-table-filters',
        filters: this.defaultFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient', 'isGuest']),
    ...mapGetters([
      'isLoading',
      'getProjectsItemsList',
      'getEnumValues',
      'getProjectsItemsListCount',
    ]),

    projects() {
      // this.getProjects.map((project) => console.log(project.number, project.customer.lastname));
      return this.getProjectsItemsList;
    },
    projectCount() {
      return this.getProjectsItemsListCount;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },
    projectStates() {
      const projectStates = this.getEnumValues('ProjectState').map((projectState) => {
        return { value: projectState, label: this.$t(projectState) };
      });
      return projectStates;
    },
    optionsYesNo() {
      return yesNo().map((option) => {
        return { value: option, label: this.$t(option) };
      });
    },
  },
  methods: {
    ...mapActions(['fetchProjectsPaginated', 'fetchEnumValues']),

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchProjectsPaginated({
        page: 0, // Start from the first page
        pageSize: 12,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
        projectListType: 'PROJECTS_WITH_ITEMS',
      });
    },

    setupFilters() {
      // console.log('setupFilters', this.tableState);
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 15;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    getProjectStateColor,
    getYesNoStateColor,
    yesNo,
    yesNoStateReversedColor,
  },
  async created() {
    this.setupFilters();
  },
  async mounted() {
    this.selectedColumns = this.columns;
    await this.fetchEnumValues('ProjectState');

    this.loading = false;
    await this.loadTableData();
  },
};
</script>
<style scoped lang="scss">
.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  padding: 0.5em;
  margin-bottom: 1em;
}

.project-entry {
  background: rgba(0, 0, 32, 0.05);
  margin: 0.5em 0;
  padding: 0.5em;
}

.project-details {
  display: grid;
  grid-template-columns: 60px 2fr 1fr;
  gap: 0.5em;
  align-items: center;
}

.project-number,
.customer-link,
.project-location {
  text-align: left;
}

.project-number {
  font-weight: bold;
}

.customer-link {
  white-space: nowrap;
}

.project-location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-card {
  color: $text-color;
}

.data-table-container {
  overflow-x: auto;
}
</style>
